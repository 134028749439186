
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner {
    display: flex;
    justify-content: start;
    position: relative;
    align-items: flex-start;
    height: auto;
    min-height: calc(100vh - 58px);

    .image {
        z-index: 2;
        & > div {
            position: absolute !important;
            left: 0;
            bottom: 0;
            height: 300px;
            width: 100vw;
            max-width: unset !important;
            left: 50% !important;
            transform: translateX(-50%) !important;

            @include lg {
                height: 100%;
                top: 0 !important;
                left: 0%;
            }
        }

        img {
            min-height: unset !important;
            min-width: unset !important;
            width: auto !important;
            height: 100% !important;
            max-width: unset !important;
            margin-left: 0 !important;
            margin-right: auto !important;
        }
    }

    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-image: url("/assets/images/payrollSystemBgDesctop.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        z-index: 2;
        @media screen and (max-width: 414px) {
            background-size: cover;
            background-image: url("/assets/images/payrollSystemBgMobile.jpg");

            // background-repeat: no-repeat;
            // background-position: right;
        }
    }
}
.back-button {
    bottom: -20px !important;
    left: -15px !important;
}
.form {
    z-index: 5;
    width: 100%;
    padding: 30px 24px;
    max-width: 700px;
    border-radius: 10px;
    min-height: 500px;
    background-color: white;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    position: relative;
    box-shadow: 0px 13px 12px 5px rgba(0, 0, 0, 0.25);

    .steps-inner {
        flex-grow: 1;
    }
    h1 {
        &.step-title {
            font-size: 20px;
            font-weight: bold !important;
            margin: 0;
            @include lg {
                font-size: 30px;
            }
        }
    }

    @include lg {
        min-width: 400px;
        width: 60%;
        padding: 40px 40px;
        margin-bottom: 200px;
        margin-top: 50px;
    }
}
.step-title {
    text-align: center;
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-bottom: 10px;
}
.label-as-step-title {
    text-align: center;
    font-size: 20px !important;
    font-weight: 700 !important;
    margin-bottom: 10px;
    margin-top: -20px;
    margin-bottom: 25px !important;
    @include lg {
        margin-left: -50px;
        margin-right: -50px;
    }
}
.step-description {
    text-align: center;
    font-weight: 300;
    margin-bottom: 2rem;
    font-size: 14px;
    @include lg {
        font-size: 18px;
    }
}
.fields-wrapper {
    margin-top: 1.5rem;
}
.form-button {
    max-width: unset !important;
    margin: 0 auto !important;
    margin-top: 5px !important;
    border-radius: 3px !important;
    background-color: $payrollsystem-default-button-color !important;
    transition: all 0.2s ease-in-out;
    @include lg {
        max-width: 450px !important;
    }
    &:hover {
        background-color: $payrollsystem-default-button-hover-color !important;
    }
}
.form-field {
    // margin-top: 2rem;
    max-width: unset;
    margin: 0 auto;
    margin-bottom: 0.5rem;
    @include lg {
        max-width: 450px;
    }
}
.checkbox-field {
    & > div {
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        input {
            &:checked + label {
                font-weight: 500;
                background-color: $payrollsystem-default-radio-selected-bgcolor;
            }
        }
        label {
            border-radius: 3px;
            border: 1px solid $payrollsystem-default-button-color;
            text-align: left !important;
            color: #000;
            font-size: 14px;
            display: flex;
            align-items: center;
            & > span:first-child {
                border-radius: 3px;
            }
            @include md {
                min-height: 50px;
            }
        }
    }
}
.radio-field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    margin-top: 10px;
    & > div {
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        input {
            &:checked + label {
                background-color: $payrollsystem-default-radio-selected-bgcolor;
            }
        }
        label {
            border-radius: 3px;
            border: 1px solid $payrollsystem-default-button-color;
            // background-color: $businessphoneprices-go-go-green;
            color: $payrollsystem-default-radio-text-color;
            font-size: 14px;
            display: flex !important;
            align-items: center;
            text-align: left !important;
            // justify-content: center;
            flex-direction: row;
            &:hover {
                background-color: $payrollsystem-default-radio-selected-bgcolor;
            }
            span {
                font-size: 16px !important;
            }
            & > div {
                margin-right: 15px;
                & + span {
                    margin-top: 0;
                    margin-right: inherit;
                    width: auto;
                }
            }
            & > span {
                margin-right: auto;
                width: 100%;
            }

            @include md {
                min-height: 50px;
            }
            svg {
                margin-bottom: 10px;
                width: 30px;
                height: auto;
            }
        }
        @include lg {
            margin: 0 10px;
            margin-bottom: 15px;
            width: 100%;
        }
    }
    @include lg {
        margin: 0 -10px;
    }
}
.tcpa {
    margin-bottom: 10px;
}
.progress-background {
    & > div:first-child span {
        color: $payrollsystem-default-progress-buble-text-color;
    }
    & > div:last-child {
        background-color: $payrollsystem-default-progress-bgcolor;
    }
}

.blocks-container {
    flex-direction: column !important;
}

.category-block {
    flex-direction: row;
    position: relative;
    padding: 20px !important;
    border: 1px solid #d1d1d1;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(229, 229, 229, 1) 100%
    );
    border: 1px solid #d1d1d1;
    filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1));
    border-radius: 5px;
    &:hover {
        border-color: #006336;
        background: #e4f2ec !important;
        color: black !important;
        cursor: pointer;
    }

    > div:nth-child(1) {
        svg {
            width: 20px;
            height: auto;

            @include md {
                width: 30px;
            }
        }
        margin-bottom: 0;
        top: 20px;
        left: 20px;
        position: absolute;
        @include md {
            position: unset;
            height: 60px;

            margin-right: 15px;
            border-right: 1px solid #000;
            padding-right: 15px;
            justify-content: center;
            align-items: center;
            display: flex;
        }
    }
    > div:nth-child(2) {
        > span {
            font-size: 16px;
            font-weight: 700;
            margin-left: 25px;
            @include md {
                margin-left: 0;
            }
        }
        font-weight: 300;
        font-size: 14px;
        text-align: left;
        line-height: 17px;
        gap: 5px;
        display: flex;
        flex-direction: column;
    }
}
