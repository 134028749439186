$payrollsystem-slogan-color: #515151;
$payrollsystem-why-us-brief-color: #747474;
$payrollsystem-default-button-color: #006336;
$payrollsystem-default-button-hover-color: #066d3e;
$payrollsystem-default-radio-selected-bgcolor: #e4f2ec;
$payrollsystem-default-radio-text-color: #000;
$payrollsystem-default-progress-color: #9bc7af;
$payrollsystem-default-progress-bgcolor: #e8f3f8;
$payrollsystem-default-progress-buble-text-color: #013d21;
:export {
    sloganColor: $payrollsystem-slogan-color;
    whyUsBriefColor: $payrollsystem-why-us-brief-color;
}
